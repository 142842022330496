import React from 'react'
import Home from './Home'
import NavIsrp from './NavIsrp'
const Isrp = () => {
    return (
        <>
            <NavIsrp/>
            <Home/>
        </>
    )
}

export default Isrp
