import { UserContext } from "./context/UserContext"
import { useContext, useEffect } from "react"


function LogoutHandler () {
    const [userContext,setUserContext] = useContext(UserContext)
    
    const logOutUser = () => {

        
        fetch(process.env.REACT_APP_API_ENDPOINT + "users/logout", {
            credentials: "include",
            headers : {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`,
            }
        }).then(async response => {
            setUserContext(oldValues => {
                return {...oldValues, details: undefined, token: null}
            })
            window.localStorage.setItem("logout", Date.now())
        })
    
    }
    return {logOutUser}
}
export default LogoutHandler