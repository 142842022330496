import React,{useState, useEffect, useContext} from 'react'
import { ThemeContext } from '../../../context/ThemeContext'
import DownloadCard from '../Cards/DownloadCard'
import {HashLink} from 'react-router-hash-link'
import Loader from '../../../Loader'
import { Modal, Button } from 'react-bootstrap'
import { getComponentData } from '../../utils/componentUtils'
import { UserContext } from '../../../context/UserContext'

const ManageDownloads = () => {
    const [downloadData,setDownloadData] = useState()
    const [show,setShow] = useState(false)
    const [promiseInfo, setPromiseInfo] = useState()
    const [userContext,setUserContext] = useContext(UserContext)
    //ADD AUTHENTICATION TO FILE AND IMAGE

    const addDownload = () => {
        getComponentData('newfile',userContext).then(() => loadDownloads())
        
    }






    const openModal = async () => {
        console.log('hey')
        setShow(true)
        const myPromise = new Promise((resolve, reject) => {
            setPromiseInfo({resolve,reject})
        });
        return myPromise
    }




    const handleHide = () => {
        promiseInfo.reject()
        setShow(false)
        
        
    }

    const loadDownloads = () => {
        getComponentData('downloads').then((data) => {
            setDownloadData(data)
            
        })
    }

     useEffect(() => {
         loadDownloads()
     },[])

    return (
        <>
    <Modal className='text-center'
        show={show}
        size="md"
        onHide={handleHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete the download?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
         <Button className='btn-danger' onClick={() => {promiseInfo.resolve(); setShow(false)}}>Delete</Button> <Button onClick={handleHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
        <div className="cms-item-wrapper text-center">
                <div className="display-4 pb-5">Manage Downloads</div>
            <h1 className="fw-light pb-5">Your Downloads</h1>
            <div className='d-flex flex-wrap justify-content-center'>
                {downloadData ? downloadData.length ===0 ? 
                
                <div className='pb-5'>
                    <div className='display-4 pb-3'>You have no downloads.</div> 
                    <div className='h5'>You have should add some :)</div>
                </div>
                 
                : 
                downloadData.map((download,index)=> {return (<DownloadCard key={index} openDeleteModal={openModal} refresh={loadDownloads} download={download}/>)}) 
                : <Loader/>}                
            </div>
            <button onClick={addDownload} className='btn btn-success mx-2 mt-2'>New Download</button>
            <HashLink role="button" type="button" to="/#downloads" className="btn mx-2 btn-primary mt-2">Go see the changes!</HashLink>
            </div>
            
        </>
    )
}

export default ManageDownloads
