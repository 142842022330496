import React, { useRef } from 'react';
import { useState, useEffect, useContext } from 'react';
import { Carousel, Modal, Button } from 'react-bootstrap';
import { Alert, Intent } from '@blueprintjs/core';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import imgTemplate from '../../../Isrp/img/imgtemplate.png'
import { getComponentData } from '../../utils/componentUtils';
import { UserContext } from '../../../context/UserContext';
import Loader from '../../../Loader';
import NewsEditor from './NewsEditor';
import EditorModal from './EditorModal';
const Overlay = styled.div`    
    background-image: ${props =>  `linear-gradient(to bottom,rgba(245, 246, 252, 0.3),rgba(17, 17, 17, 0.73)), url(${props.img})`};
    width:100%;
    background-size: cover;
    height: 500px;

`


const CustomizableNewsCarousel = (props) => {


    // const [carousels,setCarousels]=useState(null)
    const [userContext,setUserContext] = useContext(UserContext)
    const [news, setNews] = useState(props.carousel)
    const [paused,setPaused]=useState(true)
    const [currentSlide,setCurrentSlide]=useState()
    const imageRef = useRef()
    const [popupOpen,setPopupOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isUploading,setIsUploading] = useState(false)
    const [success,setSuccess] = useState(null)
    const [showEditor,setShowEditor] = useState(false)
    const changeImage = (id) => {
        setCurrentSlide(id)
        imageRef.current.click()
        
    }

    const handleChange = (e,slideId,property) =>{
        setIsEditing(true)
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
        setNews([...news].map( (slide,index) => {
            if (slide._id === slideId){
                if (property === 'imgUrl'){
                    
                    const file = imageRef.current.files[0]
                    const url = URL.createObjectURL(file)
                   
                    if (validTypes.indexOf(file.type) === -1) {
                        return slide
                    }
                    return {...slide,imgUrl:url}
                }
                else return {...slide,[property]:e.target.value}
            }
            else return slide
        })
        )
        // console.log(e.target.value,articleId)
    }



    const saveChanges = (slide) => {
        
        const formData = new FormData()
        console.log(slide)
        formData.append('id',slide._id)
        formData.append('title',slide.title)
        formData.append('desc',slide.desc)
        formData.append('image',imageRef.current.files[0])
        formData.append('parentId',props.parentId)
        
        setIsUploading(true)
        fetch(process.env.REACT_APP_API_ENDPOINT + "components/updateslide", {
            headers: {Authorization: `Bearer ${userContext.token}`},
            method: "POST",
            body:formData
            }).then(async response => {
                
            
                if (response.ok) {
                    const res = await response.json()
                    setIsEditing(false)
                    // setReadyForUpload(false)
                    setIsUploading(false)
                    
                    console.log(res)
                    props.refresh()

                    // setDownload(download => ({...download,filename:res.filename}))
                    setSuccess(true)
                }
                else {
                    setSuccess(false)
                    console.log("error")
                    // setIsUploading(false)
                }
            })

        
    }

    const deleteSlide = (slide,index) => {
        console.log(slide)
        const id = slide._id
        const imgUrl = slide.imgUrl
        console.log(news)
        const parentId = props.parentId
        fetch(process.env.REACT_APP_API_ENDPOINT + "components/deleteslide", {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`
            },
            method: "POST",
            body:JSON.stringify({id,imgUrl,parentId})
            }).then(async response => {
                
                if (response.ok) {
                    const res = await response.json()
                    setPopupOpen(false)
                    props.refresh()
                    if (index === news.length-1){
                        setPaused(false)
                    }
                }
                else {
                    console.log("error")
                    setSuccess(false)
                }
            })
        // setPopup(false)
       
        
    }

    const addSlide = () => {
        const id = props.parentId
        console.log(JSON.stringify(id))
        fetch(process.env.REACT_APP_API_ENDPOINT + "components/addslide", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`
            },
            body:JSON.stringify({id})
            }).then(async response => {
                
                if (response.ok) {
                    const res=await response.json()
                    setSuccess(true)
                    props.refresh()
                    // setNews(props.carousel)
                    // setDownload(download => ({...download,filename:res.filename}))
                    // setSuccess(true)
                }
                else {
                    setSuccess(false)
                    console.log("error")
                    // setIsUploading(false)
                }
            }).catch((err) =>{
                setSuccess(false)
            })
    }
    
    useEffect(()=>{
        setNews(props.carousel)
    },[props.carousel])

    const openPopup = (index) => {
        setCurrentSlide(index)
        setPopupOpen(true)
        
    }


    // useEffect(() => {
    //     setCarousels(carousels.map( (carousel,index) => {
    //         if (index === props.id){
    //             return {...carousel,data:news}
    //         }
    //         else return carousel
    //     }))
    // }, [news]);

    // useEffect(() => {
    //     setNews(carousels[props.id].data)
    // }, [carousels]);

    return (
        <>
            
            <div className='row justify-content-center'>
            <Alert
                confirmButtonText="Delete"
                isOpen={popupOpen} className="bp3-dark"
                cancelButtonText='Cancel'
                intent={Intent.DANGER}
                onCancel={() => {setPopupOpen(false)}}
                onConfirm={deleteSlide}
            >
                <p>Are you sure you want to <b>delete</b> the slide?</p>
  
            </Alert>
                <Carousel className='pb-2' fade interval={paused ? null : '1'} onSlid={() => setPaused(true)}>
                    {news || !isUploading ? news.length===0 ?
                    <Carousel.Item>
                        <Overlay/>
                        <Carousel.Caption>
                            <div className='h2 pb-3'>
                                You have no slides.
                            </div>
                            <button style={{transition:'500ms'}} className='btn btn-success mx-2' onClick={addSlide}>Add Slide</button> 
                        </Carousel.Caption>
                        
                    </Carousel.Item>
                    :
                    news.map((slide,index) => {
                        return (
                        
                        <Carousel.Item key={index}>
                            
                                <Overlay img={slide.imgUrl}/>
                                
                                <Carousel.Caption>
                                    <ContentEditable spellCheck="false" html={slide.title} disabled={false} tagName='h3' onChange={ (e) => handleChange(e,slide._id,'title')} />
                                    <ContentEditable spellCheck="false" html={slide.desc} disabled={false} tagName='p' onChange={ (e) => handleChange(e,slide._id,'desc')} />
                                    {/* <ContentEditable spellCheck="false" html={article.url} disabled={false} tagName='p' onChange={ (e) => handleChange(e,index,'url')} /> */}
                                    <div className='d-flex justify-content-center'>
                                        {!isEditing ?
                                        <>
                                         <button style={{transition:'500ms'}} className='btn btn-success mx-2' onClick={addSlide}>Add Slide</button>
                                        <button style={{transition:'500ms'}} className='btn btn-primary mx-2' onClick={() => changeImage(slide._id)} >Change Image</button>
                                        <button style={{transition:'500ms'}} className='btn btn-primary mx-2' onClick={() => setShowEditor(true)} >Edit Article</button>
                                        <button style={{transition:'500ms'}} className='btn btn-danger mx-2' onClick={() => deleteSlide(slide, index)} >Delete Slide</button>
                                        </> :
                                        <>
                                        <button style={{transition:'500ms'}} className='btn btn-success mx-2' onClick={() => saveChanges(slide)} >Save Changes To Slide</button>
                                        <button style={{transition:'500ms'}} className='btn btn-danger mx-2' onClick={() => {setNews(props.carousel); setIsEditing(false)}} >Cancel</button>
                                        </>
                                        }
                                    </div>
                                </Carousel.Caption>
                                <EditorModal showEditor={showEditor} article={slide.article} closeEditor={() => setShowEditor(false)} id={slide._id} parentId={props.parentId}/>
                        </Carousel.Item>
                        
                        
                        )
                        
                        
                    }) : <Loader/>}
                    
                </Carousel>
                {success!==null ? success===true ? <div className='h4 text-success'>Changes saved!</div> : <div className='h4 text-danger'>Something went wrong! Try again.</div> : null}
                <input onChange={(e) => handleChange(e,currentSlide,'imgUrl')} type={'file'} ref={imageRef} style={{display:'none'}} />
            </div>
        </>);
};

export default CustomizableNewsCarousel;
