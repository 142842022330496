import { useCallback,useEffect, useState, useContext } from "react"
import {Card,CardGroup} from "react-bootstrap"
import {ThemeContext} from '../../context/ThemeContext'
import { getComponentData } from "../utils/componentUtils"
function CardList() {

    const [employees,setEmployees] = useState(null)
    const {darkMode,cardColor} = useContext (ThemeContext)
    const [data,setData] = useState(null)
    const [color,setColor] = cardColor

    useEffect(() => {
        
        getComponentData('employees',null).then( (data) => {
            setData(data)
        })

    }, []);
    

    const updateCards = () => {
        setEmployees(
            data ? data.map((employee) => 
        <Card key={employee._id} style={{backgroundColor: darkMode ? "#242424" : `${color.bg}`,color: darkMode ? "#fff" : `${color.text}` }} >
            <div className="card-img" style={{backgroundImage:`url(${employee.imgUrl})`}}> </div>
            <Card.Body>
            <Card.Title >{employee.fullName.replaceAll('<br>','')}</Card.Title>
            <Card.Text style={{textAlign:"center"}}>
                {employee.desc.replaceAll('<br>','')}
            </Card.Text>
            </Card.Body>
        </Card>
        ): null)
        }

    useEffect (() => {
    //     fetch(process.env.REACT_APP_API_ENDPOINT + "components/employees", {
    //         method: "GET",
    //         headers: {
    //             "Content-Type" : "application/json"
    //         }
    //     }).then(async response => {
    //     if (response.ok) {
    //         const data = await response.json()
            updateCards()
        // }
    //     else {
    //         if (response.status===401){
    //             console.log("401")
    //         }
    //     }
    // })
    
    },[data])


    return (

        <CardGroup>
            {employees}
        </CardGroup>
    )
    
} 


export default CardList