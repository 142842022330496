import React, { useContext } from 'react';
import { GetIcon } from '../../utils/componentUtils';
import ContentEditable from 'react-contenteditable';
import { useState} from 'react';
import { UserContext } from '../../../context/UserContext';
const ProductCard = (props) => {
    const [userContext,setUserContext] = useContext(UserContext)
    const [success, setSuccess] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [product, setProduct] = useState(props.product)
    const handleChange = (e,property) =>{
        setIsEditing(true)
        setProduct ({...product,[property]:e.target.value})
    }
      const onSave = () => {
        const id=product._id
        const desc=product.desc
        fetch(process.env.REACT_APP_API_ENDPOINT + "products/update", {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`
            },
            method: "POST",
            body:JSON.stringify({id,desc})
            }).then(async response => {
                
                
                if (response.ok) {
                    const res = await response.json()
                    console.log('success')
                    setSuccess(true)
                    // loadProducts()
                    
                }
                else {
                    setSuccess(false)
                }
                setIsEditing(false)
              })
        }
  return (
  <div className='pb-5'> 
    <div className="col d-flex m-auto align-items-center px-2 justify-content-center">
        {GetIcon(product.icon)}
        <div className='px-2'>
            <h4 className="fw-bold">{product.title}</h4>
        </div>
    </div>
    <ContentEditable className='mt-3' spellCheck="false" html={product.desc} disabled={false} tagName='h3' onChange={ (e) => handleChange(e,'desc')} />
    {isEditing && <>
                    <button className='btn btn-success mx-2' onClick={onSave}>Save Title</button>
                    <button className='btn btn-danger mx-2' onClick={() => {setProduct(props.product);setIsEditing(false)}} >Cancel</button>
                    </>}
                    {success!==null ? success===true ? <div className='h4 text-success'>Changes saved!</div> : <div className='h4 text-danger'>Something went wrong! Try again.</div> : null}
    </div>
    )
};
export default ProductCard;