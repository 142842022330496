import React from 'react'
import herobg from './img/herobg.png'
import space1 from './img/space1.jpg'
import planebg from './img/courrielbg.png'
import EmployeeCards from '../components/Home/EmployeeCards'
import Downloads from '../components/Home/Downloads'
import * as MdIcon from 'react-icons/md'
import * as FaIcon from 'react-icons/fa'
import * as VscIcon from 'react-icons/vsc'
import * as GiIcon from 'react-icons/gi'
import * as AiIcon from 'react-icons/ai'
import { IconContext } from 'react-icons/lib'
import { Accordion, OverlayTrigger, Tooltip, Carousel } from 'react-bootstrap'
import { Tooltip2, Classes } from '@blueprintjs/popover2'
import News from '../components/Home/News'
import CardCarousel from '../components/CMS/Cards/CardCarousel'
import Products from '../components/Home/Products'
const Home = () => {
    return (
    <div className='home-wrapper'>
        <section style={{backgroundImage:`url(${herobg})`}} className="d-flex hero justify-content-center align-items-center">
            <div className="container mb-auto mt-5 display-1 fw-bold hero-text position-relative zoom-init hero-anim">
                L'INSTITUT SOCIOLOGIQUE<br/><span style={{color:'#a50097'}}>RES PUBLICA</span>
            </div>
        </section>
        <section className='section pb-5' id='produits' >
            <div className='container text-center pt-5'>
            <div className="display-4 fw-bold lh-1">
                    Nous vous accompagnons de l'analyse des besoins à la réalisation de la recherche.
                </div>
                <h4 className='display-4 pt-5'>Nous offrons une gamme de services tels que</h4>
                
                <div className="py-5" >
                   
                    <IconContext.Provider value={{size:"50px"}}>
                        <div className="row row-cols-lg-3 justify-content-center py-5 icon-container m-auto">
                            <Products/>
                        </div>
                    </IconContext.Provider>
                    
                    {/* <h4 className='display-6 pt-5'>Nous vous présentons les résultats selon vos besoins que ce soit sous la forme d'un plan d'action et
                        d'implémentation, de recommandations de politiques ou d'identification des problèmes.</h4> */}
                    {/* <h4 className='display-6 py-5'>Cliquez <a href="#contact" style={{color:'#a50097'}}>ici</a> afin de communiquer avec nous.</h4> */}
                    <h4 className='display-6 pt-3'>Il est aussi possible d'adhérer à l'Institut <a className='isrp-link'>ici</a>. Ce statut vous permet
                     d'avoir accès à une série de privilèges.</h4>
                     {/* <h4 className="display-6 pt-3">Pour plus de renseignements, visitez le lien suivant :</h4>  */}
                    
                </div>
                
            </div>
        </section>
        <section className="section pb-5" id="quisommesnous">
            <div className='container text-center pt-5'>
                <div className="display-4 fw-bold lh-1">
                    L'Institut sociologique Res Publica vous accompagne vers la francophonie de demain.
                </div>
                <h4 className='pt-4'>L'accès à la connaissance constitue une nécessité au développement de la communauté francophone.
                Les politiques basées sur les données probantes permettent de mieux identifier les besoins et de cibler
                les actions à mener. Notre visée pragmatique vous aidera à établir les prochaines actions pour votre
                organisme.</h4>
                <h4 className='pt-4'>L'Institut sociologique Res Publica (IRSP) est un projet philanthropique à but non lucratif qui vous
                accompagne dans tous vos projets de recherche de l'élaboration des méthodes à l'analyse des données.</h4>
                <div className='row justify-content-center'>
                    <Accordion className='py-5 qsm-accordion' alwaysOpen defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><div className='display-4 ms-auto'>Les chercheurs associés</div></Accordion.Header>
                            <Accordion.Body>
                            <CardCarousel id='620a91f971439b47764762c4' />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><div className='display-4 ms-auto'>Conseil d'administration</div></Accordion.Header>
                            <Accordion.Body>
                            <CardCarousel id='62194508bb22ad32e8e0ce83'/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><div className='display-4 ms-auto'>Structure de l'organisme</div></Accordion.Header>
                            <Accordion.Body>
                            <CardCarousel id='62194519bb22ad32e8e0ce84' />
                            </Accordion.Body>
                        </Accordion.Item>   
                    </Accordion>
                </div>
                {/* <h1 className='pt-4 display-4'>Conseil d'administration</h1>
                <h1 className='pt-4 display-4'>Structure de l'organisme</h1> */}
            </div>
        </section>
        <section className='section' id='donnees'>
            <div className='container text-center py-5'>
                <div className="display-6 fw-bold lh-1">
                L'IRSP vous offre un accès à une série de données. Nous offrons un service d'analyse au besoin. Notez
                    que certains documents peuvent être payants.
                </div>
                <div className='display-4 py-5 fw-bold 1h-1'>
                    Données statistiques
                </div>
                <div style={{color:'black'}}>
                    <Downloads btnColor="#6dadc4" btnTextColor="white"/>
                   
                </div>
            </div>
        </section>
        <section className="section" id="nouvelles">
            <div className='text-center py-5'>
                <div className='display-4 fw-bold 1h-1'>
                    Nouvelles
                </div>
                <div className='py-5 flex-wrap m-auto d-flex justify-content-center'>
                    <News/>
                </div>
            </div>
        </section>
        <section className="section position-relative" id="infolettre" style={{backgroundImage:`url(${planebg}), linear-gradient(to left,#94d9e0, #93d9e0)`}}>
        
            <div className='container text-center py-5'>
                
                <div className='display-4 fw-bold 1h-1 pb-3'>
                    Infolettre
                </div>
                <h4>
                Pour plus d'information concernant les réalisations de l'Institut, vous pouvez vous abonner à notre infolettre.</h4>
                <div className='py-5 flex-wrap m-auto d-flex justify-content-center'>
            
                <form>
                    <div className="mb-5">
                        <label htmlFor="nom" className="form-label display-4">Nom</label>
                        <input type="text" className="form-control form-rounded"/>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="prenom" className="form-label display-4">Prénom</label>
                        <input type="text" className="form-control form-rounded" />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="courriel" className="form-label display-4">Courriel</label>
                        <input type="email" className="form-control form-rounded" />
                    </div>
                    <button type="submit" className="btn abv-btn btn-outline-light rounded border-2"><h3>Abonnez-vous à notre info-lettre</h3></button>
                </form>
                </div>
                
            </div>
           
        </section>
        <section className='section d-flex align-items-center' id="contact">
            <div className="text-center m-auto">
                <div className='container py-5'>
                    <div className='display-4 fw-bold 1h-1'>
                        N'hésitez pas à nous contacter.
                    </div>
                </div>
                <IconContext.Provider value={{size:'3rem'}}>
                <div className="row w-100 text-center py-5 row-cols-1 justify-content-center row-cols-lg-3">
                    <div className="feature col">
                        <div>
                            <MdIcon.MdOutlineEmail/>
                        </div>
                        <h3>contact@isrp.ca</h3>
                    </div>
                    <div className="feature col">
                        <div>
                        <MdIcon.MdOutlinePhone/>
                        </div>

                        <h3>1-506-984-1607</h3>
                    </div>
                    </div>
                </IconContext.Provider>
            </div>
        </section>
    </div>
    )
}

export default Home
