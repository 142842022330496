import React,{useContext, useState, useEffect} from 'react'
import CustomizableCard from './CustomizableCard'
import Loader from '../../../Loader'
import { getComponentData } from '../../utils/componentUtils'
import { UserContext } from '../../../context/UserContext'
const CardCustomization = (props) => {
    const [cardData,setCardData] = useState(props.cards)
    const [userContext,setUserContext] = useContext(UserContext)
    
    // const addCard = () => {
    //     const newId =cardData.length+1
    //     const newEmployee = {fullName:"Enter Name",description:"Enter Description",_id:newId}
    //     setCardData([...cardData,newEmployee])
        

    // }


    const addCard = () => {
        const id = props.parentId
        console.log(JSON.stringify(id))
        fetch(process.env.REACT_APP_API_ENDPOINT + "cards/card/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`
            },
            body:JSON.stringify({id})
            }).then(async response => {
                
                if (response.ok) {
                    const res=await response.json()
                 
                    props.refresh()
                    // setNews(props.carousel)
                    // setDownload(download => ({...download,filename:res.filename}))
                    // setSuccess(true)
                }
                else {
                   
                    console.log("error")
                    // setIsUploading(false)
                }
            }).catch((err) =>{
                
            })
    }
    
    const loadEmployees = () =>{
        props.refresh()
    }

    useEffect(() => {setCardData(props.cards)},[props.cards])
    

    return (
        <>
            <div className="fw-light pb-5 display-5">{props.title}</div>
            <div className="d-flex flex-wrap justify-content-center pb-2">
                { cardData ? cardData.length === 0 ? 
                <div className='pb-5'>
                    <div className='display-6 pb-3'>You have no cards.</div> 
                    <div className='h5'>You have should add some :)</div>
                </div>
                : cardData.map( (employee,index) => {
                return (<CustomizableCard parentId={props.parentId} refresh={loadEmployees} key={index} card={employee}/>)
                }) : <Loader/>}
            </div>
            <button onClick={addCard} className="btn btn-success mb-5">Add new card</button>
            {/* <div className="row py-lg-2">
                    <div className="col-lg-10 col-md-8 mx-auto">
                        <h1 className="fw-light">Change Card Background Color</h1>
                        <div className="col-4 pt-4 text-center d-flex mx-auto justify-content-center">
                            <SwitchColor cardBgColor={"blue"} btnTheme={"btn-primary"} />
                            <SwitchColor cardBgColor={"red"} btnTheme={"btn-danger"} />
                            <SwitchColor cardBgColor={"black"} btnTheme={"btn-dark"} />
                            <SwitchColor cardBgColor={"white"} btnTheme={"btn-light"} />
                        </div>
                        
                        <hr/>
                    </div>
                </div>
                <div className="row py-lg-2">
                    <div className="col-lg-10 col-md-8 mx-auto">
                        <h1 className="fw-light">Change Card Text Color</h1>
                        <div className="col-4 pt-4 text-center d-flex mx-auto justify-content-center">
                            <SwitchColor cardTextColor={"blue"} btnTheme={"btn-primary"} />
                            <SwitchColor cardTextColor={"red"} btnTheme={"btn-danger"} />
                            <SwitchColor cardTextColor={"black"} btnTheme={"btn-dark"} />
                            <SwitchColor cardTextColor={"white"} btnTheme={"btn-light"} />
                        </div>
                        
                        <hr/>
                    </div>
                </div> */}
            {/* <HashLink role="button" type="button" scroll={(el) => el.scrollIntoView({behavior: 'auto', block: 'end'})} to="/#cards" className="btn btn-primary btn-lg px-4 me-sm-3">Go see the changes!</HashLink> */}
        </>
    )
}

export default CardCustomization
