import React,{useContext, useEffect, useState} from 'react'
import { ThemeContext } from '../../context/ThemeContext'
import Loader from '../../Loader'
import { Modal, Button } from "react-bootstrap";
import { getComponentData } from '../utils/componentUtils'

const Downloads = (props) => {

    const [cards,setCards]=useState()
    const [showDownloads,setShowDownloads] = useState(false)
    const [downloads, setDownloads] = useState(<Loader/>)
    useEffect(() => {
        getComponentData('downloads',null).then((data) =>{
            
            setCards( data ?
                data.map((card,index) => {
                return( 
                <>
                <div key={index} className='card download-card m-2 p-3'>
                    <h4>{card.name.replaceAll('<br>','')}</h4>
                    <p>{card.desc.replaceAll('<br>','')}</p>
                    <p>{card.filename}</p>
            
                    <a role="button" href={card.link} download={card.filename} type="button" style={{background:props.btnColor,color:props.btnTextColor}} className="btn download-btn rounded-0 btn-lg">Download</a>
                </div>
                
                </>
                )

            }): <Loader/>)
        })
    },[])



    return (
        <> 
        
        <Modal show={showDownloads} fullscreen={true} onHide={() => setShowDownloads(false)} className='article'>
            <Modal.Header closeButton>
            
            </Modal.Header>
            <Modal.Body >
                
            <div className='display-4 text-center mb-4'>Données statistiques</div>
                <div className='d-flex flex-wrap text-center justify-content-center'>
                    {cards}
                </div>
            </Modal.Body>
        </Modal>


        <div className="d-flex flex-wrap justify-content-center">
            
            {cards}
            
        </div>
        <button className="btn btn-outline-light btn-lg mt-5" onClick={() => setShowDownloads(true)}>Afficher tout</button>
        </>

        )   
}

export default Downloads
