import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import Loader from '../../Loader';
import {GetIcon, getData} from '../utils/componentUtils';


const Products = () => {

    const [products,setProducts] = useState()

    useEffect(() => {
        getData('products/list').then( (data) => {
            setProducts(data)
        })
    }, []);
    
  return <> 
    { products ? products.map((product,index) => 
            {
            return(
                <OverlayTrigger key={index} placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip><div dangerouslySetInnerHTML={{__html:product.desc}}></div></Tooltip>}>

                    <div className="col d-flex align-items-center px-2 pb-5">
                        {GetIcon(product.icon)}
                        <div className='px-2'>
                            <h4 className="fw-bold mb-2">{product.title.replaceAll('<br>','')}</h4>
                        </div>
                    </div>
                </OverlayTrigger>
                )
            }
        ) : <Loader/>
    } 
    </> 
};

export default Products;
