import {Button, Card} from "@blueprintjs/core"
import React, {useCallback, useContext, useEffect, useState} from "react"
import {UserContext} from "./context/UserContext"
import Loader from "./Loader"
import styled from "styled-components"
import DarkModeSwitch from "./components/CMS/Theme/DarkModeSwitch"
import { ThemeContext } from "./context/ThemeContext"
import {SwitchColor, DefaultColor} from "./components/CMS/Theme/Buttons/SwitchColor"


const Welcome = () => {
    
    const {darkMode,bgColor} = useContext(ThemeContext)
    const [bgColorVal,setBgColorVal] =bgColor
    const [userContext,setUserContext] = useContext(UserContext)

    const setBgColor = (newColor) => e => {
        setBgColorVal(newColor);
    }



    // const fetchUserDetails = useCallback(() => {
    //     fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
    //         method: "GET",
    //         credentials: "include",
    //         headers: {
    //             "Content-Type" : "application/json",
    //             Authorization: `Bearer ${userContext.token}`
    //         }
    // }).then(async response => {
    //     if (response.ok) {
    //         const data = await response.json()
    //         setUserContext(oldValues => {
    //             return {...oldValues, details:data}
    //         })
    //     }
    //     else {
    //         if (response.status===401){
    //             window.location.reload()
    //         }
    //         else {
    //             setUserContext(oldValues => {
    //                 return {...oldValues, details: null}
    //             })
    //         }
    //     }
    // })
    // }, [setUserContext, userContext.token])
    
    // useEffect(() => {
    //     if(!userContext.details){
    //         fetchUserDetails()
    //     }
    // }, [userContext.details, fetchUserDetails])
   

    const refetchHandler = () => {
        setUserContext(oldValues => {
            return {...oldValues, details:undefined}
        })
    }
    // return userContext.details=== null ? ("Error loading user info") : !userContext.details ? (<Loader />) : (
        return(
        <>

    
        
        <div className="cms-item-wrapper text-center" >
            <div className="display-4 pb-5">Dashboard Demo</div>
            <DarkModeSwitch/>
            <section className="container">
                <div className="row py-lg-5">
                    <div className="col-lg-10 col-md-8 mx-auto">
                        <h1 className="fw-light">Change background color</h1>
                        <div className="col-4 pt-4 text-center d-flex mx-auto justify-content-center">
                            <SwitchColor bgColor={"blue"} btnTheme={"btn-primary"} />
                            <SwitchColor bgColor={"red"} btnTheme={"btn-danger"} />
                            <SwitchColor bgColor={"black"} btnTheme={"btn-dark"} />
                        </div>
                        <DefaultColor componenet={"background"} />
                        <hr/>
                    </div>
                </div>
                <div className="row py-lg-5">
                    <div className="col-lg-10 col-md-8 mx-auto">
                        <h1 className="fw-light ">Change header colour</h1>
                        <div className="col-4 pt-4 text-center d-flex mx-auto justify-content-center">
                            <SwitchColor headerColor={"blue"} btnTheme={"btn-primary"} />
                            <SwitchColor headerColor={"red"} btnTheme={"btn-danger"} />
                            <SwitchColor headerColor={"black"} btnTheme={"btn-dark"} />
                        </div>
                        <DefaultColor componenet={"header"} />
                        <hr/>
                    </div>
                </div>
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h1 className="fw-light">Change text colour</h1>
                        <div className="col-4 pt-4 text-center d-flex mx-auto justify-content-center">
                            <SwitchColor textColor={"blue"} btnTheme={"btn-primary"} />
                            <SwitchColor textColor={"red"} btnTheme={"btn-danger"} />
                            <SwitchColor textColor={"white"} btnTheme={"btn-light"} />
                        </div>
                        <DefaultColor componenet={"text"} />
                        <hr/>
                    </div>
                </div>
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h1 className="fw-light">Change font</h1>
                        <div className="col-4 pt-4 text-center d-flex mx-auto justify-content-center">
                            <SwitchColor font={"arial"} btnTheme={"btn-light"} />
                            <SwitchColor font={"monospace"} btnTheme={"btn-light"} />
                            <SwitchColor font={"impact"} btnTheme={"btn-light"} />
                        </div>
                        <DefaultColor componenet={"font"} />
                        <hr/>
                    </div>
                </div>
            </section>
           
            {/* <Card elevation="1">
                <div className="user-details">
                    <div>
                        <p>
                            Welcome&nbsp;
                            <strong>{userContext.details.firstName}
                            {userContext.details.lastName && " " + userContext.details.lastName}</strong>!
                        </p>
                    </div>
                    <div className="user-actions">
                        <Button text="Refetch" intent="primary" onClick={refetchHandler} />
                    </div>
                </div>
            </Card> */}
            {/* <ManageEmployees/> */}
        </div>
        </>
    )
}
export default Welcome