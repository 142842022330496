import React,{useContext} from 'react'
import { UserContext } from './context/UserContext';
import { ThemeContext } from './context/ThemeContext';
import { Alignment, Button, Navbar } from "@blueprintjs/core";
import LogoutHandler from './LogoutHandler'
import { Link } from 'react-router-dom';
const MainNavbar = () => {
    const [userContext, setUserContext] = useContext(UserContext)
    const {logOutUser } = LogoutHandler()
    const {headerColor} = useContext(ThemeContext)
    const [headerColorVal,setHeaderColorVal] = headerColor
    return (
     
        <Navbar style={{backgroundColor:`${headerColorVal}`}} fixedToTop="true" className="bp3-dark">
          <Navbar.Group align={Alignment.LEFT}>

          
            <Navbar.Heading>Ordinix CMS</Navbar.Heading>

            <Navbar.Divider/>
              <Link to="/">
                <Button className="bp3-minimal">Home</Button>
              </Link>
              
              
              {/* <Link to="/cms/customize/cards">
                <Button className="bp3-minimal">CMS</Button>
              </Link> */}
              {/* <Link to="/isrp">
                <Button className="bp3-minimal">ISRP</Button>
              </Link> */}
              {/* <Link to="/register">
                <Button className="bp3-minimal">Register</Button>
              </Link> */}
              {userContext.token &&

              <Button className="bp3-minimal" onClick={() => logOutUser()}>Logout</Button>

              }
          </Navbar.Group>

        </Navbar>
      
    )
}

export default MainNavbar
