import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { UserProvider} from "./context/UserContext"
import { DarkModeContext } from "./context/ThemeContext";
import Layout from './Layout';
import ContextWrapper from './context/ContextWrapper';

ReactDOM.render(
  <React.StrictMode>
    <ContextWrapper>
        <UserProvider>
          <Layout>
            <App />
          </Layout>
        </UserProvider>
      </ContextWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)
