import React,{useContext} from 'react'
import { ThemeContext } from '../../../../context/ThemeContext'
const SwitchColor = (props) => {
    const {bgColor, headerColor, textColor,cardColor,font} = useContext(ThemeContext)
    const [bgColorVal,setBgColorVal] = bgColor
    const [headerColorVal,setHeaderColorVal] = headerColor
    const [textColorVal,setTextColorVal] = textColor
    const [cardColorVal,setCardColorVal] = cardColor
    const [fontName,setFontName] = font
    const setColor = () => {
        if (props.bgColor){
            setBgColorVal(props.bgColor)
        }
        else if (props.headerColor){
            setHeaderColorVal(props.headerColor)
        }
        else if (props.textColor){
            setTextColorVal(props.textColor)
        }
        else if (props.font){
            setFontName(props.font)
        }
        else if (props.cardBgColor){
            setCardColorVal({...cardColorVal,bg:props.cardBgColor})
        }
        else if (props.cardTextColor){
            setCardColorVal({...cardColorVal,text:props.cardTextColor})
        }
        
    }

    return (
        <div className='px-3'>
            <button onClick={setColor} style={{fontFamily:`${props.font}`}} className={`btn ${props.btnTheme} p-4 border-dark border-2 rounded-0`}>{props.font}</button>
        </div>
    )
}


const DefaultColor = (props) => {
    const {bgColor, headerColor, textColor,font} = useContext(ThemeContext)
    const [bgColorVal,setBgColorVal] = bgColor
    const [headerColorVal,setHeaderColorVal] = headerColor
    const [textColorVal,setTextColorVal] = textColor

    

    const [fontName,setFontName] = font
    const resetColor = () => {
        if (props.componenet == "background"){
            setBgColorVal("white")
        }
        else if (props.componenet == "header"){
            setHeaderColorVal("#394b59")
        }
        else if (props.componenet == "text"){
            setTextColorVal("#000")
        }
        else if (props.componenet == "font"){
            setFontName(null)
        }
        
    }
    return (
        <div className="col-4 text-center mx-auto py-4">
        <button className="btn btn-outline-secondary btn-lg px-4" onClick={resetColor}>
            Default
         </button>
        </div>
    )
}

export {SwitchColor, DefaultColor}
