import React,{useState,useContext, useEffect} from 'react';
import { Carousel,Card} from 'react-bootstrap';
import { ThemeContext } from '../../../context/ThemeContext';
import Loader from '../../../Loader';
import { getData } from '../../utils/componentUtils';
const CardCarousel = (props) => {
    const {darkMode,cardColor} = useContext (ThemeContext)
    const [cardData,setCardData] = useState(null)
    const [color,setColor] = cardColor


    const loadData = () =>{
        getData('cards/cardsections',null).then((data) => {
            console.log(data.find(section => section._id===props.id))
            setCardData((data.find(section => section._id===props.id).cards))
        })
    }

    useEffect(() => {
        loadData()
    },[])

  return (
  <>
  <Carousel controls={false} fade={true} className='w-100' variant='dark'>
   {
       cardData ?
            cardData.map((employee) => {
                return(
                    <Carousel.Item key={employee._id}>
                        <Card className='pb-5' key={employee._id} style={{backgroundColor:'#6dadc4',color:'white'}} >
                            <div className="card-img" style={{backgroundImage:`url(${employee.imgUrl})`}}> </div>
                            <Card.Body>
                            <Card.Title >{employee.fullName.replaceAll('<br>','')}</Card.Title>
                            <Card.Text style={{textAlign:"center"}}>
                                {employee.desc.replaceAll('<br>','')}
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                )
            } 
            ) : <Loader/>
    }
    </Carousel>
    </>
  )
};

export default CardCarousel;
