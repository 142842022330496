import { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../context/ThemeContext";

export default () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);  
  return (
    <div className="py-4">
    <button className="btn btn-outline-secondary btn-lg px-4" onClick={toggleDarkMode}>
     {darkMode ? "Back to normal" : "dark mode"}
    </button>
    </div>
  );
};



