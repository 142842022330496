import { useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import employeeimg1 from '../img/employees/employee1.jpg'
import employeeimg2 from '../img/employees/employee2.jpg'
import space1 from '../Isrp/img/space1.jpg'
import space2 from '../Isrp/img/space2.jpg'
import space3 from '../Isrp/img/space3.jpg'
import * as MdIcon from 'react-icons/md'
import * as FaIcon from 'react-icons/fa'
import * as VscIcon from 'react-icons/vsc'
import * as GiIcon from 'react-icons/gi'
export default props => {
  const [darkMode, setDarkMode] = useState(false);
  const [bgColor, setBgColor] = useState("white")
  const [cardColor, setCardColor] = useState({bg:"white",text:"black"})
  const [headerColor, setHeaderColor] = useState("#394b59")
  const [textColor, setTextColor] = useState("#000")
  const [fontName, setFontName] = useState(null)
  const [downloadCardData,setDownloadCardData] = useState([{link:'',name:'Download Name',desc:'Download description',filename:'',id:1}])
  const [newsData,setNewsData] = useState(
    [
      
      {
        title:'Space',
        data:
        [{
        title:`Capturing all that glitters in galaxies with NASA's Webb Telescope`,
        url:"https://phys.org/news/2022-01-capturing-glitters-galaxies-nasa-webb.html",
        reference:`by NASA's Goddard Space Flight Center`,
        img:space1
        },
        {
        title:`NASA's James Webb telescope is about to arrive at an exceptional point in space`,
        url:"https://www.popsci.com/science/james-webb-telescope-destination-lagrange-point/",
        reference:`By Rahul Rao`,
        img:space2
        },
        {
        title:`Star search: Canadian scientist to travel way back in time using world's largest space telescope`,
        url:"https://www.cbc.ca/news/canada/edmonton/star-search-canadian-scientist-to-travel-way-back-in-time-using-world-s-largest-space-telescope-1.6322347",
        reference:`By Liam Harrap`,
        img:space3
        },
        ]
      }
    ]
  
  );





  const [productData, setProductData] = useState([
    {
      title:'Analyse de politiques publiques',
      desc:'Enter Text Here',
      icon:<MdIcon.MdPublic/>
    },
    {
      title:"L'analyse qualitative (textes, codes, entrevues)",
      desc:'Enter Text Here',
      icon:<FaIcon.FaBook/>
    },
    {
      title:"L'analyse statistique",
      desc:'Enter Text Here',
      icon:<VscIcon.VscGraphLine/>
    },
    {
      title:"L'identification des problèmes",
      desc:'Enter Text Here',
      icon:<GiIcon.GiMagnifyingGlass/>
    },
    {
      title:"La revue de littérature",
      desc:'Enter Text Here',
      icon:<GiIcon.GiNotebook/>
    }
  ]);




  // 

//   const [cardData,setCardData] = useState([{
//     _id:"1",
//     imgUrl:employeeimg1,
//     fullName:"Demo Employee",
//     description:"This is a demo description",
    
// },
// {
//     _id:"2",
//     imgUrl:employeeimg2,
//     fullName:"Demo Employee",
//     description:"This is another demo description"
// }])



  function toggleDarkMode() {
    setDarkMode(darkMode => !darkMode);
  }
  
  return (
    <ThemeContext.Provider value={{ darkMode, downloadCardData:[downloadCardData,setDownloadCardData], toggleDarkMode, bgColor:[bgColor,setBgColor], headerColor:[headerColor,setHeaderColor], cardColor:[cardColor,setCardColor],
    textColor:[textColor,setTextColor],font:[fontName,setFontName], newsData:[newsData,setNewsData],
    productData:[productData,setProductData]
    }}>{props.children}
    </ThemeContext.Provider>
  );
};