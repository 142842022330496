import React, { useContext, useEffect, useState } from 'react';
import * as Io from 'react-icons/io'
import * as Md from 'react-icons/md'
import CustomizableNewsCarousel from './CustomizableNewsCarousel';
import { getComponentData } from '../../utils/componentUtils';
import Loader from '../../../Loader';
import ContentEditable from 'react-contenteditable';
import { UserContext } from '../../../context/UserContext';
import CustomizeNewsArticle from './NewsEditor';
const CustomizeNews = () => {
  const [userContext,setUserContext] = useContext(UserContext)
  const [carousels,setCarousels] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const [success, setSuccess] = useState(null)

  const newCarousel = () => {
    getComponentData('newcarousel',userContext).then((data) => {
      loadCarousels()
    })
    

  }

  const handleChange = (e,id) =>{
    setIsEditing(true)
    setCarousels([...carousels].map(carousel => {
        if (carousel._id==id){
          return {...carousel,title:e.target.value}
          
        }
        return carousel
    })
    )
  }

  const onSave = (id,newTitle) => {
      fetch(process.env.REACT_APP_API_ENDPOINT + "components/changecarouseltitle", {
          headers: {
          'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`
          },
          method: "POST",
          body:JSON.stringify({id,newTitle})
          }).then(async response => {
              if (response.ok) {

                  setSuccess(true)
                  loadCarousels()
                  
              }
              else {
                  setSuccess(false)
              }
              setIsEditing(false)
            })
      // setPopup(false)
     
      
  }

  const loadCarousels = () => {
    getComponentData('newscarousels',null).then((data) => {
      setCarousels(data)
    })
  }

  useEffect(() => {
    loadCarousels()
  },[])

  // const deleteCarousel = (id) =>{

  //   setCarousels(carousels.filter((carousel,index) => {return  0 !== index}))

  // }


  const deleteCarousel = (carousel) => {
    console.log(carousel.slides)
    const id = carousel._id
    const images = carousel.slides.map(slide => slide.imgUrl)
    fetch(process.env.REACT_APP_API_ENDPOINT + "components/deletecarousel", {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userContext.token}`
        },
        method: "POST",
        body:JSON.stringify({id,images})
        }).then(async response => {
            if (response.ok) {
                // props.refresh()
                loadCarousels()
            }
            else {
                console.log("error")
                
            }
        })
    // setPopupOpen(false)
    
}  


  return (
    <div className="cms-item-wrapper text-center">
        <div className="display-4 pb-5">Modify News</div>

        {carousels ? carousels.length===0 ?
        <div className='pb-5'>
            <div className='display-4 pb-3'>You have no news carousels.</div> 
            <div className='h5'>You have should add some :)</div>
          </div>
        :
        carousels.map((carousel,index) => {
          return(
            <div key={carousel._id} className='py-3'>
              <div className='mb-5'>
                <ContentEditable spellCheck="false" html={carousel.title} disabled={false} tagName='div' className='display-5 mb-2' onChange={(e) => handleChange(e,carousel._id)} />
                {isEditing && <>
                <button className='btn btn-success mx-2' onClick={() => onSave(carousel._id, carousel.title)}>Save Title</button>
                <button className='btn btn-danger mx-2' onClick={() => {loadCarousels(); setIsEditing(false)}} >Cancel</button>
                </>}
                {success!==null ? success===true ? <div className='h4 text-success'>Changes saved!</div> : <div className='h4 text-danger'>Something went wrong! Try again.</div> : null}
                </div>
               <CustomizableNewsCarousel carousel={carousel.slides} parentId={carousel._id} refresh={loadCarousels}/>
                <button className='btn btn-danger mt-2' onClick={() => deleteCarousel(carousel)}>Delete News Carousel</button>
              
            </div>
            )
        }) : <Loader/> }
        <div className='text-underline-hover' onClick={newCarousel}>
          <Io.IoIosAddCircleOutline color='green' size='60px'/>
          <div className='display-5 pb-2' style={{color:'green'}}>
            Add Carousel
          </div>
        </div>
        
        {/* <button className="btn mt-5 btn-primary" onClick={newCarousel}>Add News Carousel</button> */}
    </div>
  )
};

export default CustomizeNews;
