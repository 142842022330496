import React, { useState } from 'react'
import './Isrp.css'
import { Link } from 'react-router-dom'
import Logo from './img/logoisrp_t_b.png'
const NavIsrp = () => {
    const [isCollapsed,setIsCollapsed] = useState(true)
    return (

       <>
       <nav className="navbar fixed-top navbar-expand-custom navbar-dark bg-light">
            <div className="container-md navbar-container">
                <button className="navbar-toggler" type="button" onClick={() => setIsCollapsed(!isCollapsed)}  >
                <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href="#"><img alt="isrp" style={{width:"50px"}} src={Logo}/> ISRP</a>
                <div className={`collapse pt-1 navbar-collapse ${isCollapsed ? null : "show"} `} id="navbar" >
                    <ul className="navbar-nav m-auto mb-lg-0" onClick={() => setIsCollapsed(!isCollapsed)} >
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="#">Accueil</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#produits">Produits</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#quisommesnous">Qui sommes-nous?</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#donnees">Données</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#nouvelles">Nouvelles</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#infolettre">Infolettre</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact">Contact</a>
                        </li>
                    </ul>
                <form className="d-flex">
                    <input className="form-control me-2 recherche-btn" type="search" placeholder="Recherche" aria-label="Search"/>
                    <button className="btn btn-outline-light" type="submit">Rechercher</button>
                </form>
                </div>
            </div>
        </nav>
       </>
    )
}

export default NavIsrp
