import React,{useState, useContext} from 'react'
import styled from 'styled-components';
import {Link, Outlet} from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu'
import Welcome from '../../Welcome';
import { IconContext } from 'react-icons/lib';
import { ThemeContext } from '../../context/ThemeContext';


const Nav = styled.div`
    background: #F8F9FA;
    height: 80px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover{
        color:#fff;
    }

`;

const SidebarNav = styled.nav`
    background: ${props => (
    !props.darkMode ? "#F8F9FA": "#060606")};
    width: 250px;
    height: 100vh;
    color: #000;
    display: flex;
    justify-content: center;
    position: fixed;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    left: ${({sidebar}) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
    `

const SidebarWrap = styled.div`
    width: 100%;`

const Sidebar = () => {
    const [sidebar, setSidebar] = useState(true)
    const {darkMode} = useContext(ThemeContext)
    const showSidebar = () =>
        setSidebar(!sidebar)


    return (
        <>
        
            {/* <Nav>
                <NavIcon to="#">
                    <FaIcons.FaBars onClick={showSidebar}/>
                </NavIcon>
            </Nav> */}
            <SidebarNav darkMode={darkMode} sidebar={sidebar}>
                <SidebarWrap>
                    {/* <NavIcon to="#">
                        <AiIcons.AiOutlineClose onClick={showSidebar}/>
                    </NavIcon> */}
                    {SidebarData.map((item,index) => {
                        return <SubMenu item={item} key={index}/>
                    })}
                </SidebarWrap>
            </SidebarNav>
     
        <Outlet/>
        </>
    )
}

export default Sidebar
