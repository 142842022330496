import React,{useState,useRef, useContext,useEffect} from 'react'
import ContentEditable from 'react-contenteditable'
import Loader from '../../../Loader'
import { UserContext } from '../../../context/UserContext'

const DownloadCard = (props) => {
    const [userContext,setUserContext] = useContext(UserContext)
    const [isUploading,setIsUploading] = useState(false)
    const [isEditing,setIsEditing] = useState(false)
    const [readyForUpload,setReadyForUpload] = useState(false)
    const [download,setDownload] = useState (props.download)
    const [success,setSuccess] = useState(null)
    const downloadRef = useRef(null)
    const fileInputClicked = () => {
        downloadRef.current.click();
    }

    const revertChanges = () => {
        setDownload(props.download)
        setIsEditing(false)
        setReadyForUpload(false)
    }

    const deleteDownload = () => {

        const id = download._id
        const filename = download.filename
        fetch(process.env.REACT_APP_API_ENDPOINT + "components/deletefile", {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`},
            method: "POST",
            body:JSON.stringify({id,filename})
            }).then(async response => {
                if (response.ok) {
                    props.refresh()
                }
                else {
                    setSuccess(false)
                    
                }
            })
    }

    const updateDownload = () => {
        
        const file = downloadRef.current.files[0]
        const formData = new FormData()
        formData.append("name",download.name)
        formData.append("id",download._id)
        formData.append("desc",download.desc)
        if (readyForUpload) formData.append("file", file)
        setIsUploading(true)
        fetch(process.env.REACT_APP_API_ENDPOINT + "components/updatefile", {
            headers:{Authorization: `Bearer ${userContext.token}`},
            method: "POST",
            body:formData
            }).then(async response => {
                if (response.ok) {
                    const res = await response.json()
                    if (res.success){
                        setIsEditing(false)
                        setReadyForUpload(false)
                        
                        setDownload(download => ({...download,filename:res.filename}))
                        setSuccess(true)
                    }
                    else setSuccess(false)

                }
                else {
                    setSuccess(false)
                    console.log("error")
                    setIsUploading(false)
                }
                setIsUploading(false)
            })

        
    }

    

    const onFileUpload = () => {
        const name = downloadRef.current.files[0].name
        setReadyForUpload(true)
        setDownload(download => ({...download,filename:name}))

    }

    const showModal = () => {
        setTimeout(async () => {
            try{
                const result = await props.openDeleteModal()
                deleteDownload()
            } catch (err) {
                console.log(err)
                
            }
        })
    }

    const updateName = e => {
        setIsEditing(true)
        setDownload(download => ({...download,name:e.target.value}))
    }
    const updateDesc = e => {
        setIsEditing(true)
        setDownload(download => ({...download,desc:e.target.value}))
    }

    useEffect(() => {

        setDownload(props.download)
        
    }, [props.download])

    return (
        <div className='card download-card align-self-center m-2 p-3'>
            {!isUploading ? <><ContentEditable spellCheck="false" html={download.name} disabled={false} tagName='h4' onChange={updateName}/>
            <ContentEditable spellCheck="false" html={download.desc} disabled={false} tagName='p' onChange={updateDesc}/>
            {success!==null ? success===true ? <div className='h4 text-success'>Changes saved!</div> : <div className='h4 text-danger'>Something went wrong! Try again.</div> : null}
            <p>{download.filename}</p>
            <div className='d-flex justify-content-center'>
            {
                readyForUpload || isEditing ? 
                <>
                    <button onClick={updateDownload} className="btn btn-success btn-lg mx-2">Save Changes</button>
                    <button type="button" onClick={fileInputClicked} className="btn btn-primary btn-lg mx-2">Upload</button>
                    <button onClick={revertChanges} className="btn btn-danger btn-lg mx-2">Cancel</button>
                </> :
                <>
                    <button type="button" onClick={fileInputClicked} className="btn btn-primary btn-lg mx-2">Upload</button>
                    {download.link!=='' && <a role="button" href={download.link} download type="button" className=" btn-primary btn-lg mx-2">Download</a>}
                    <button type="button" onClick={ showModal} className="btn btn-danger btn-lg mx-2">Delete</button>
                </>
                }

           </div>
           <input onChange={onFileUpload} type={'file'} ref={downloadRef} style={{display:'none'}} ></input></> : <Loader/>}
        </div>
    )
}

export default DownloadCard
