import React, { useState, useContext,useEffect } from 'react'
import CardCustomization from './CardCustomization'
import { UserContext } from '../../../context/UserContext'
import { getData } from '../../utils/componentUtils'
import Loader from '../../../Loader'

const CardSection = () => {
  const [sectionsData, setSectionsData] = useState()
  const [userContext,setUserContext] = useContext(UserContext)


  const loadEmployees = () =>{
    getData('cards/cardsections').then (( data) => {
        setSectionsData(data)
    })   
    }
    useEffect(() => {
      loadEmployees()

    }, []);

  return (
    <div className="cms-item-wrapper text-center">
      <div className="display-4 pb-5">Modify Cards</div>
      {sectionsData ? sectionsData.map(section => {
          return <CardCustomization title={section.title} parentId={section._id} refresh={loadEmployees} cards={section.cards} />
        }  
      ) : <Loader/>}
      
    </div>
  )
}

export default CardSection