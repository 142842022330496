import React, {useContext, useEffect, useState} from 'react'
import { Modal, Button } from 'react-bootstrap'
import NewsEditor from './NewsEditor'
import { UserContext } from '../../../context/UserContext'
const EditorModal = (props) => {
    const [userContext,setUserContext] = useContext(UserContext)
    const [article, setArticle] = useState(props.article)
    const handleCancel = () => {
       setArticle(props.article)
       props.closeEditor()
    }

   const onSave = () => {
        const id = props.id
        const parentId =props.parentId
        const html = article
        console.log(JSON.stringify({id,html,parentId}))
        fetch(process.env.REACT_APP_API_ENDPOINT + "components/updatearticle", {
            headers: {'Content-Type': 'application/json',Authorization: `Bearer ${userContext.token}`},
            method: "POST",
            
            body: JSON.stringify({id,html,parentId})
            }).then(async response => {
                
            
                if (response.ok) {
                    const res = await response.json()
                    console.log(res)
                    
                    // setIsUploading(false)
                    
                    // console.log(res)
                    // props.refresh()

                    // setSuccess(true)
                }
                else {
                    // setSuccess(false)
                    console.log("error")
                    // setIsUploading(false)
                }
            })
            props.closeEditor()
   }

  return (
    <Modal fullscreen={true} show={props.showEditor} onHide={props.closeEditor}>
        <Modal.Header closeButton>
        <Modal.Title>Edit Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <NewsEditor html={article} setArticle={setArticle}/>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" onClick={handleCancel}>
            Cancel
        </Button>
        <Button variant="primary" onClick={onSave}>
            Save Changes
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default EditorModal