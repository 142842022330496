import React,{useRef,useState,useCallback,useContext,useEffect} from 'react'
import CardDropzone from './CardDropzone'
import ContentEditable from 'react-contenteditable'
import { ThemeContext } from '../../../context/ThemeContext'
import { getComponentData } from '../../utils/componentUtils'
import Loader from '../../../Loader'
import { UserContext } from '../../../context/UserContext'
import { Modal, Button } from 'react-bootstrap';
const CustomizableCard = (props) => {
    const [popup,setPopup] = useState(false)
    const [userContext,setUserContext] = useContext(UserContext)
    const {cardColor,darkMode} = useContext(ThemeContext)
    const [color,setColor] = cardColor
    const [card, setCard] = useState(props.card)
    const [imgForUpload,setImgForUpload] = useState()
    const [isUploading,setIsUploading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [success,setSuccess] = useState(null)
    // const cardTitleRef = useRef()
    // const cardDescRef= useRef()
    
    
    const handleTitleChange = e => {
        setIsEditing(true)
        setCard(download => ({...download,fullName:e.target.value}))
    }
    const handleDescChange = e => {
        setIsEditing(true)
        setCard(download => ({...download,desc:e.target.value}))
    }

    
    const deleteCard = (slide,index) => {
        const id = card._id
        const imgUrl = card.imgUrl
        const parentId = props.parentId
        fetch(process.env.REACT_APP_API_ENDPOINT + "cards/card/delete", {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`
            },
            method: "POST",
            body:JSON.stringify({id,imgUrl,parentId})
            }).then(async response => {
                
                if (response.ok) {
                    const res = await response.json()
                    props.refresh()
                }
                else {
                    console.log("error")
                }
            })
        // setPopup(false)
       
        
    }


    const onSave = () => {
        const formData = new FormData()
        formData.append("fullName",card.fullName)
        formData.append("id",card._id)
        formData.append("desc",card.desc)
        formData.append('parentId',props.parentId)
        if (imgForUpload) formData.append("image", imgForUpload.img, imgForUpload.name)
        setIsUploading(true)
        fetch(process.env.REACT_APP_API_ENDPOINT + "cards/card/update", {
            headers:{Authorization: `Bearer ${userContext.token}`},
            method: "POST",
            body:formData
            }).then(async response => {
                if (response.ok) {
                    setIsEditing(false)
                    setImgForUpload(null)
                    setIsUploading(false)
                    props.refresh()
                    const res = await response.json()
                    console.log(res)
                    setSuccess(true)
                }
                else {
                    setSuccess(false)
                    console.log("error")
                    setIsUploading(false)
                }
            })
    }




    // const updateEmployees = () => {
        
    //     setCardData([...cardData].map(employee => {
    //         if (employee._id === props.id){
                
    //             return {...employee,fullName:cardTitle,description:cardDesc}
    //         }
    //         else return employee
    //     }) 
    //     )
        
    // }

    // useEffect(() => {
    //   getComponentData('employees').then((data) => {
    //       setCardData(data)
    //   })
    

    // }, []);
    

    useEffect(() => {
        setCard(props.card)
    }, [props.card])
    return (
        <>
                <Modal className='text-center'
                    show={popup}
                    size="md"
                    onHide={() => setPopup(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        Are you sure you want to delete the card?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button className='btn-danger' onClick={() => {setPopup(false); deleteCard()}}>Delete</Button> <Button onClick={() => setPopup(false)}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            
            <div className="card m-3 text-center" style={{width: "18rem",height:'400px',backgroundColor: darkMode ? "#242424" : `${color.bg}`,color: darkMode ? "#fff" : `${color.text}` }}>
            {isUploading ? <Loader/> :   <>
                    <CardDropzone setImgForUpload={setImgForUpload}  setCard={setCard} openPopup={() =>setPopup(true)} card={card}/>
                    
                    <div className="card-body">
                    {success!==null ? success===true ? <div className='h4 text-success'>Changes saved!</div> : <div className='h4 text-danger'>Something went wrong! Try again.</div> : null}
                        <ContentEditable spellCheck="false" className="card-title" html={card.fullName} disabled={false} tagName='h5' onChange={handleTitleChange} />
                        <ContentEditable spellCheck="false" className="card-text" html={card.desc} disabled={false} tagName='p' onChange={handleDescChange} />
                        {
                    (imgForUpload || isEditing) &&
                    <>
                        <button role="button" onClick={onSave} className="btn btn-success btn-md mx-2">Save Changes</button>
                        <button role="button" onClick={() => {setCard(props.card); setImgForUpload(null); setIsEditing(false);}} className="btn btn-danger btn-md mx-2">Cancel</button>
                    </>
                    }
                    </div>
                </>
                }
            </div>
            
    </> 
    )
}

export default CustomizableCard
