import React, {useEffect, useMemo, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';



const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'link', 'image', 'align'
]
const NewsEditor = (props) => {
    const [value, setValue] = useState('')
    

    const modules = useMemo(() => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'code-block',{'align':[]}],
        ]
      }
    }), [])
  let formats = [
   'video', 'image', 'link', 'blockquote',
   'bold', 'italic', 'underline', 'list', 
   'strike', 'header','align'
 ]

    const handleChange = (e) => {
      props.setArticle(e)
    }

  return (
    <div className="text-editor">
        <ReactQuill style={{height:'100%'}} theme="snow" 

                    modules={modules}
                    formats={formats} value={props.html} onChange={handleChange}
                    >
        </ReactQuill>
        
    </div>
  
  )
}


export default NewsEditor