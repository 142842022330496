import CMS from "./CMS"
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom"
import Register from "./Register";
import Footer from "./Footer";
import Welcome from "./Welcome";
import CardSection from "./components/CMS/Cards/CardSection";
import ManageDownloads from "./components/CMS/Downloads/ManageDownloads";
import Isrp from "./Isrp/Isrp";
import CustomizeProducts from "./components/CMS/Products/CustomizeProducts";
import CustomizeNews from "./components/CMS/News/CustomizeNews";
import Login from "./Login";

function App() {



  return  (
    <Router>
      
      

     
        
        <Routes>
          
          <Route path="/login" element={<Login/>}/>
          <Route path="/cms" element={<CMS/>}>
              <Route path="" element={<Navigate to="customize/cards"/>}/>
              <Route path="customize">
                <Route path="general" element={<Welcome/>}/>
                <Route path="cards" element={<CardSection/>}/>
                <Route path="downloads" element={<ManageDownloads/>}/>
                <Route path="news" element={<CustomizeNews/>}/>
                <Route path="products" element={<CustomizeProducts/>}/>
              </Route>
              <Route path="overview" element={<CMS/>}/>
          </Route>

          <Route path="/register" element={<Register/>} />
          {/* <Route path="/cms-front-end" element={<Home/>} /> */}
          {/* <Route path="/" element={<Navigate to="/cms-front-end/"/>}/> */}
          <Route path="/" element={<Isrp/>}>
          
          </Route>
        </Routes>
        <Footer/>
        

              
    </Router>
  )
  
}

export default App;
