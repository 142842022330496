import {Card, Tabs, Tab} from "@blueprintjs/core"
import { useContext, useCallback, useState, useEffect } from "react"
import Login from "./Login"
import Loader from "./Loader"
import Register from "./Register"
import Welcome from "./Welcome"
import './App.css';
import { UserContext } from "./context/UserContext"
import Sidebar from "./components/CMS/Sidebar"
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import MainNavbar from "./MainNavbar"


function CMS() {
  const [currentTab, setCurrentTab] = useState("login")
  const [userContext, setUserContext] = useContext(UserContext)
  
  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type" : "application/json"},
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        setUserContext(oldValues => {
          return {...oldValues, token: data.token}
        })
      } else {
        setUserContext(oldValues => {
          return {...oldValues, token: null}
        })
      }
      setTimeout(verifyUser,5*60*1000)
    })
  }, [setUserContext])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])
  
  return userContext.token === null ? (
    <div className="wrapper">
      <Card elevation="1">

        <Tabs id="Tabs" onChange={setCurrentTab} selectedTabId={currentTab}>
          <Tab id="login" title="Login" panel={<Login />} />
          {/* <Tab id="register" title="Register" panel={} /> */}
          <Tabs.Expander />
        </Tabs>
      </Card>
      </div>
      
  ) : userContext.token ? (
    <>
    <MainNavbar/>
     <div className="cms-wrapper">
       <Sidebar/>
    </div>
  </>
  ) : ( <Loader />)
  // return (
  //   <>
  //     <MainNavbar/>
  //      <div className="cms-wrapper">
  //        <Sidebar/>
  //     </div>
  //   </>
  //   )
  
}

export default CMS;
