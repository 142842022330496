import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'
import * as BsIcons from 'react-icons/bs'

export const SidebarData = [
    {
        title: 'Overview',
        path: 'overview',
        icon : <AiIcons.AiFillHome />,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        disabled: true
    },
    {
        title: 'Customize',
        path: '#',
        icon: <AiIcons.AiFillSetting/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'General',
                path: 'customize/general',
                icon: <IoIcons.IoIosPaper/>,
                disabled:true
            },
            {
                title: 'Pricing',
                path: '/customize/employeecards',
                icon: <IoIcons.IoIosPaper/>,
                disabled: true
            },
            {
                title: 'Cards',
                path: 'customize/cards',
                icon: <IoIcons.IoIosPaper/>,
                
            },
            {
                title: 'Downloads',
                path: 'customize/downloads',
                icon: <IoIcons.IoIosPaper/>
            },
            {
                title: 'News',
                path: 'customize/news',
                icon: <IoIcons.IoIosPaper/>
            },
            {
                title: 'Products',
                path: 'customize/products',
                icon: <IoIcons.IoIosPaper/>
            }
        ]
    }
]