import { useContext, useState } from 'react';
import styled,{createGlobalStyle} from 'styled-components';
import { ThemeContext } from './context/ThemeContext';

const Layout = styled.div`
    transition: 500ms;
    background-color: ${props => (
    props.darkMode ? "black" : props.bgColor
    )};
    color: ${props => props.darkMode ? "white" : props.textColor};
    font-family: ${props => props.font};
`

export default props => {
  const { darkMode,bgColor,textColor, font } = useContext(ThemeContext); 
  const [bgColorVal,setBgColorVal] = bgColor
  const [textColorVal,setTextColorVal] = textColor
  const [fontName,setFontName] = font



  return (
    bgColorVal ? <Layout darkMode={darkMode} font={fontName} textColor={textColorVal} bgColor={bgColorVal} >
        {props.children}
    </Layout> : null
)}