import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons/lib';
import Loader from '../../../Loader';
import { getData } from '../../utils/componentUtils';
import ProductCard from './ProductCard';


const CustomizeProducts = () => {
  const [products,setProducts] = useState()
  const loadProducts = () => {
    getData('products/list').then( (data) => {
      setProducts(data)
  })
  }

  useEffect(() => {
    loadProducts()
  }, []);

  
    // setPopup(false)
   
    

  return  <div className="cms-item-wrapper text-center">
            <div className="display-4 pb-5">Manage products</div>
            <h1 className="fw-light pb-5">Your Products</h1>
              <div className='w-50 m-auto'>
              <IconContext.Provider value={{size:"50px"}}>
                {products ? products.map((product) => {
                  return <ProductCard product={product} key={product._id}/>
                } ) : <Loader/>}
              </IconContext.Provider>
              </div>
          </div>
};

export default CustomizeProducts;
