import { Carousel } from "react-bootstrap";
import React, {useEffect, useState } from 'react';
import styled from "styled-components";
import './news.css'
import { getComponentData } from "../utils/componentUtils";
import Loader from "../../Loader";
import { Modal, Button } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
// color: ${props => props.isDarkMode ? "white" : "black"};

const Overlay = styled.div`    
    background-image: ${props =>  `linear-gradient(to bottom,rgba(245, 246, 252, 0.3),rgba(17, 17, 17, 0.73)), url(${props.img})`};
    width:100%;
    background-size: cover;
    height: 500px;

`


const News = () => {
    const [showArticle,setShowArticle] = useState(false)
    const [article, setArticle] = useState({html:'',title:''})
    //SUBDOCUMENTS INTEAD
    const [carousels,setCarousels]=useState(null)
    useEffect(() => {
        getComponentData('newscarousels').then((data) => {
            setCarousels(data)
        })
    }, [])
  return(
  <>
  <Modal show={showArticle} fullscreen={true} onHide={() => setShowArticle(false)} className='article'>
        <Modal.Header closeButton>
          <Modal.Title>{article.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="ql-editor" dangerouslySetInnerHTML={{__html:article.html}}></div></Modal.Body>
      </Modal>
    { carousels ? carousels.map((carousel) => {
        return (
        <Carousel className="carousel-hover" key={carousel._id} fade >
            {carousel.slides.map((slide,index) => {
                return (
                <Carousel.Item onClick={() => {setArticle({html:slide.article,title:slide.title});setShowArticle(true)}} key={index}>
                    
                        <Overlay img={slide.imgUrl}/>
                        
                        <Carousel.Caption>
                        
                            <h3>{slide.title.replaceAll('<br>','')}</h3>
                            <p>{slide.desc.replaceAll('<br>','')}</p>
                        
                        </Carousel.Caption>
                
                </Carousel.Item>)
            })}
        </Carousel>
        )
    }) : <Loader/>
    
    }
    </>
  )
};

export default News;
