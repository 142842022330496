import React,{useRef,useState,useEffect,useCallback,useContext} from 'react'
import './Dropzone.css';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../Crop/utils/GetCroppedImg';
import * as Bs from 'react-icons/bs'
import { Modal, Button } from 'react-bootstrap';
import { UserContext } from '../../../context/UserContext'
const CardDropzone = (props) => {
    const [card,setCard] = useState(props.card)
    const fileInputRef = useRef();
    const modalImageRef = useRef();
    const modalRef = useRef();
    const progressRef = useRef();
    const uploadRef = useRef();
    const uploadModalRef = useRef();
    const [errorMessage, setErrorMessage] = useState('');
    const [croppedPixels,setCroppedPixels] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [cropImg,setCropImg] = useState(null)
    
 

    

    const onCropComplete = useCallback( async (croppedArea, croppedAreaPixels) => {
        setCroppedPixels(croppedAreaPixels)
    }, [])

    const preventDefault = (e) => {
        e.preventDefault();
        // e.stopPropagation();
    }

    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        
        if (e.dataTransfer.files.length==1) {
            const file = e.dataTransfer.files[0]
            handleFile(file);
        }
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length ==1) {
            handleFile(fileInputRef.current.files[0]);
        }
    }



    const saveImg = async() => {
        const newImg = await getCroppedImg(cropImg,croppedPixels)
        if(newImg){
            props.setImgForUpload({img:newImg,name:fileInputRef.current.files[0].name})
            updateImg(URL.createObjectURL(newImg))
            modalRef.current.style.display="none"
        }
        else{
            console.log("Error try again")
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }
    const updateImg = (image) =>{
        props.setCard(card => ({...card,imgUrl:image}));
        console.log('img set')
    }

    
    const closeModal = () => {
        modalRef.current.style.display="none"
    }

    const handleFile = (file) => {
        console.log(file)
        const reader = new FileReader();
        console.log("Validating file..")
        if (validateFile(file)){

            
            reader.readAsDataURL(file);
            reader.onload = function(e) {
                setCropImg(reader.result)
                
                modalRef.current.style.display="block"
            }
        }
        else{
            console.log("invalid")

            file['invalid'] = true;
        }
        
            
            
    }
    

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
        console.log(file)
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const fileSize = (size) => {
        if (size === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }


    const fileType = (fileName) => {
        // return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    useEffect(() => {
        setCard(props.card)
    },[props.card])



    return (
<>              

            <div className="position-relative h-100">
            <div className='drag-drop-close'>
                    <Bs.BsFillTrashFill color='red' size='40px'  onClick={() => props.openPopup()}/>
                   
                </div>
                
                <div className="drop-container mx-auto"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked} ref={modalImageRef}
                    style={{backgroundImage:`url(${props.card.imgUrl})`}}

                >
                     
                    <div className="container">
                    <div className="drop-message">
                        <div className="upload-icon"></div>
                        {!card.imgUrl && <div><Bs.BsFillCloudUploadFill size='50px'/></div>}
                    </div>
                    <input
                        ref={fileInputRef}
                        className="file-input"
                        type="file"
                        multiple
                        onChange={filesSelected}
                    />
                </div>


                </div>
            </div>
            <div className="modal" ref={modalRef}>
                <div className="overlay"></div>
                <div className="mx-auto zindex-sticky  crop-wrapper">
                    <div className="crop-container pt-5 m-auto mt-5">
                        <Cropper
                        image={cropImg}
                        crop={crop}
                        zoom={zoom}
                        aspect={3 / 2}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        />
                        
                    </div>
                    <div className='mx-auto w-25 pt-4'>
                        <button className='btn mx-2 btn-lg btn-block btn-primary' onClick={saveImg}>Save</button>
                        <button className='btn mx-2 btn-lg btn-block btn-primary' onClick={closeModal}>Close</button>
                    </div>
                </div>
                
            </div>

            <div className="upload-modal" ref={uploadModalRef}>
                <div className="overlay"></div>
                
                <div className="progress-container">
                    <span ref={uploadRef}></span>
                    <div className="progress">
                        <div className="progress-bar" ref={progressRef}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardDropzone
