import * as fa from 'react-icons/fa'
import * as vsc from 'react-icons/vsc'
import * as gi from 'react-icons/gi'
import * as md from 'react-icons/md'
import { useContext } from 'react'
const icons = new Object

icons['fa'] = fa
icons['vsc'] = vsc
icons['gi'] = gi
icons['md'] = md

export const getComponentData = (component,userContext) => {
    return fetch(process.env.REACT_APP_API_ENDPOINT + "components/"+component, {
        method: "GET",
        headers: {
            "Content-Type" : "application/json",
            Authorization: `Bearer ${userContext ? userContext.token: null}`
        }
    }).then(async response => {
    if (response.ok) {
        
        const data = await response.json()
        return data
     
    }
    else {
        if (response.status===401){
            console.log("401")
        }
    }
  })
};

export const getData = (component) => {
    return fetch(process.env.REACT_APP_API_ENDPOINT +component, {
        method: "GET",
        headers: {
            "Content-Type" : "application/json"
        }
    }).then(async response => {
    if (response.ok) {
        
        const data = await response.json()
        return data
     
    }
    else {
        if (response.status===401){
            console.log("401")
        }
    }
  })
};

export const GetIcon = (iconName) => {
    if (iconName){
        const [prefix,suffix] = iconName.split('.')
        const IconComponent = icons[prefix][suffix]
        return <IconComponent/>
    }
    else{
        return <md.MdOutlineEmail/>
    }
    

}
