import React,{useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import { ThemeContext } from '../../context/ThemeContext'
import { SidebarData } from './SidebarData'

const SidebarLink = styled(Link)`
    display:flex;
    color: ${props => props.isdarkmode};
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        color: #e1e9fc;
        background: #394b59;
        text-decoration: none;
        border-left: 4px solid #465765;
        cursor: pointer;
    }
`

const DropdownLink = styled(Link)`
    background: ${props => props.isdarkmode};
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.isdarkmodetext};
    font-size: 18px;
    
    &:hover{
        background: #394b59;
        cursor: pointer;
    }
    
`

const SidebarLabel = styled.span`
    margin-left: 16px;
    `

const SubMenu = ({item}) => {
    
    const [subnav, setSubnav] = useState(false)
    const {darkMode} = useContext(ThemeContext)
    const showSubnav = () => setSubnav(!subnav)

    return (
        <>  
            <SidebarLink isdarkmode={darkMode ? 'white' : 'black'} className={item.disabled ? "disabled-menu" : null} to={item.path} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </SidebarLink>
            {subnav && item.subNav.map((item,index) => {
                return (
                    <DropdownLink isdarkmodetext={darkMode ? 'white' : 'black'} isdarkmode={darkMode ? "#060606": "#E3E3E3"} className={item.disabled ? "disabled-submenu" : null} to={item.path} key={index}>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
                )
            })}
        </>
    )
}

export default SubMenu